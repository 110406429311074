import React, { useEffect, useState } from "react";

const BackToTopButton = ({isRajasthan=false , isMadhya=false, isHR = false, isUP = false, isBH = false}) => {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scrolled up to given distance
  const toggleVisibility = () => {
    const scrolled =
      document.documentElement.scrollTop || document.body.scrollTop;
    if (scrolled > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <div id="back-to-top" className="block fixed transition-all bottom-[5rem] md:bottom-[6.5rem] right-4 z-100 md:z-10">
      {isVisible && (
        <button
          onClick={scrollToTop}
          className={`${isRajasthan ? 'bg-rajasthanDark':isMadhya ? 'bg-mpDark':isHR?'bg-hrDark':isBH?'bg-biharDark':isUP?'bg-upDark':'bg-primary'} text-white font-thin py-2 px-2 rounded`}
        >
          TOP
        </button>
      )}
    </div>
  );
};

export default BackToTopButton;
